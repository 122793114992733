body,
html {
  height: 100%;
  margin: 0;
}

.unsupported-browser-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: var(--zui-color-gs-20);
  place-content: center center;
}

.unsupported-browser-content {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--zui-color-gs-10);
}

.unsupported-browser-information-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.unsupported-browser-image-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.unsupported-browser-information-content {
  padding-left: 80px;
}

.unsupported-browser-message-header {
  color: #353d45;
  font: var(--zui-typography-h1);
}

.unsupported-browser-link-container {
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  color: var(--zui-color-pb-100);
  font: var(--zui-typography-subtitle1);
}

.unsupported-browser-supported-browser-link {
  padding-right: 8px;
  color: var(--zui-color-pb-100);
  cursor: pointer;
  text-decoration: underline;
}

.javascript-deactivated {
  display: flex;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 16px);
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
}

.error-page-container {
  position: relative;
  display: flex;
  width: 772px;
  min-height: 367px;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 79px;
  padding-bottom: 40px;
  padding-left: 80px;
  background-color: #fff;
  color: #353d45;
}

.error-label {
  position: absolute;
  top: 40px;
  left: 80px;
  font: normal 600 14px / 1.4286 'Segoe UI', sans-serif;
}

.error-header {
  margin-top: 64px;
  margin-bottom: 16px;
  font: normal 200 48px / 1.5 'Segoe UI', sans-serif;
}

.error-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font: normal 400 14px / 1.4286 'Segoe UI', sans-serif;
}

.error-text1 {
  margin: 0;
}

.error-text2 {
  margin: 16px 0 0;
}
